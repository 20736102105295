import React from 'react'
import { graphql } from 'gatsby'

import Layout from './components/layout'
import SEO from './components/seo'


export const query = graphql`  
   query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
      }
  }
  allLinkedListJson {
    edges {
      node {
        id
        link
        label
        site_id
      }
    }
  }
}
`

 
export default ({ data }) => {
  function createMarkup() {
    return {__html: data.markdownRemark.html};
  }
  return (
    <Layout navlist={data.allLinkedListJson.edges}>
      <SEO title={data.markdownRemark.frontmatter.title} />
      <div className="container-fluid bg-secondary justify-content-center">
      <div className="text-center pt-5">
        <div className="p-5" dangerouslySetInnerHTML={createMarkup()} />
      </div>
      </div>
     
    </Layout>
  )
}

